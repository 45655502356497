/* global site */

// Import site modules
import ('./site/gsap-anims.js');
import ('./site/soumissions.js');

window.site = (window.site || {});

/**
 * Main application class.
 * @class App
 * @static
 */
window.site.App = (function App() {
  /**
   * Has the class been initialized?
   * @private
   */
  let inited = false;

  /**
   * Application config defaults.
   * @private
   * @param config.env     Current server environment
   * @param config.csrf    Security token to submit with forms
   * @param config.csrfName    Security token to submit with forms
   * @param config.locale  Current locale short code
   * @param config.device  Device detection based on browser signature
   * @param config.preview Page is shown through live preview mode
   * @param config.general Settings from general config
   */
  const config = {
    env: 'production',
    csrf: null,
    csrfName: null,
    locale: 'en',
    device: 'desktop',
    preview: false,
    general: {},
  };

  /**
   * Initializes the class.
   * @public
   */
  const init = function init(options) {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    // Store application settings
    options = (options || {});

    if (options.env) { config.env = options.env; }
    if (options.csrf) { config.csrf = options.csrf; }
    if (options.csrfName) { config.csrfName = options.csrfName; }
    if (options.locale) { config.locale = options.locale; }
    if (options.device) { config.device = options.device; }
    if (options.preview) { config.preview = options.preview; }
    if (options.general) { config.general = options.general; }

    // Global
    // Detect css-grid un-supported
    const el = document.createElement("div")
    const supportsGrid = "string" == typeof el.style.grid;

    if(!supportsGrid) {
      const body = document.getElementsByTagName("body")[0].classList.add('is-ie-11');
    } else {
      // IE 11 || Old Browser - Ignore zone
      console.table(options);

      // Register ServiceWorker
      if ('serviceWorker' in navigator) {
        // console.log('Registering ServiceWorker...');
        // window.addEventListener('load', function() {
        //     navigator.serviceWorker.register('/sw.js', {
        //         scope: "/"
        //     }).then(function(registration) {
        //         // Registration was successful
        //         console.log('ServiceWorker registration successful with scope: ', registration.scope);
        //         // Trim the caches on load
        //         navigator.serviceWorker.controller && navigator.serviceWorker.controller.postMessage({
        //             command: "trimCaches"
        //         });
        //    }).catch(function(err) {
        //         // registration failed :(
        //         console.log('ServiceWorker registration failed: ', err);
        //     });
        // });
      }

      // PreRender for fast website
      function prerenderlink(e) {
        var head = document.getElementsByTagName("head")[0];
        var refs = head.childNodes;
        var ref = refs[ refs.length - 1];

        var elements = head.getElementsByTagName("link");
        Array.prototype.forEach.call(elements, function(el, i) {
          if (("rel" in el) && (el.rel === "prerender"))
            el.parentNode.removeChild(el);
        });

        var prerenderTag = document.createElement("link");
        prerenderTag.rel = "prerender";
        prerenderTag.href = e.currentTarget.href;
        ref.parentNode.insertBefore(prerenderTag,  ref);
      }

      const $prerenderLinks = document.querySelectorAll('#page-header a');
      [].slice.call($prerenderLinks).forEach(function(elem) {
        elem.addEventListener('mouseenter', prerenderlink);
      });
    }

    /*--Menu mobile--*/
    const hamburger = document.querySelector("#hamburger");
    const overlay = document.querySelector("#mobilemenu .overlay");
    const mobilemenu = document.querySelector("#mobilemenu");

    hamburger.addEventListener('click', function() {
      hamburger.classList.toggle("open");
      mobilemenu.classList.toggle("open");
    }, false);

    overlay.addEventListener('click', function() {
      hamburger.classList.remove("open");
      mobilemenu.classList.remove("open");
    }, false);

    if (document.querySelector('#map')) {
      // When the window has finished loading create our google map below
      google.maps.event.addDomListener(window, 'load', init);

      function init() {
          // Basic options for a simple Google Map
          // For more options see: https://developers.google.com/maps/documentation/javascript/reference#MapOptions
          var mapOptions = {
            // How zoomed in you want the map to start at (always required)
            zoom: 15,

            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,

            // The latitude and longitude to center the map (always required)
            center: new google.maps.LatLng(45.613560, -73.666930),

            // How you would like to style the map.
            // This is where you would paste any style found on Snazzy Maps.
            styles: [
              {
                  "featureType": "all",
                  "elementType": "labels.text.fill",
                  "stylers": [
                      {
                          "saturation": 36
                      },
                      {
                          "color": "#000000"
                      },
                      {
                          "lightness": 40
                      }
                  ]
              },
              {
                  "featureType": "all",
                  "elementType": "labels.text.stroke",
                  "stylers": [
                      {
                          "visibility": "on"
                      },
                      {
                          "color": "#000000"
                      },
                      {
                          "lightness": 16
                      }
                  ]
              },
              {
                  "featureType": "all",
                  "elementType": "labels.icon",
                  "stylers": [
                      {
                          "visibility": "off"
                      }
                  ]
              },
              {
                  "featureType": "administrative",
                  "elementType": "geometry.fill",
                  "stylers": [
                      {
                          "color": "#000000"
                      },
                      {
                          "lightness": 20
                      }
                  ]
              },
              {
                  "featureType": "administrative",
                  "elementType": "geometry.stroke",
                  "stylers": [
                      {
                          "color": "#000000"
                      },
                      {
                          "lightness": 17
                      },
                      {
                          "weight": 1.2
                      }
                  ]
              },
              {
                  "featureType": "landscape",
                  "elementType": "geometry",
                  "stylers": [
                      {
                          "color": "#000000"
                      },
                      {
                          "lightness": 20
                      }
                  ]
              },
              {
                  "featureType": "poi",
                  "elementType": "geometry",
                  "stylers": [
                      {
                          "color": "#000000"
                      },
                      {
                          "lightness": 21
                      }
                  ]
              },
              {
                  "featureType": "road.highway",
                  "elementType": "geometry.fill",
                  "stylers": [
                      {
                          "color": "#000000"
                      },
                      {
                          "lightness": 17
                      }
                  ]
              },
              {
                  "featureType": "road.highway",
                  "elementType": "geometry.stroke",
                  "stylers": [
                      {
                          "color": "#000000"
                      },
                      {
                          "lightness": 29
                      },
                      {
                          "weight": 0.2
                      }
                  ]
              },
              {
                  "featureType": "road.arterial",
                  "elementType": "geometry",
                  "stylers": [
                      {
                          "color": "#000000"
                      },
                      {
                          "lightness": 18
                      }
                  ]
              },
              {
                  "featureType": "road.local",
                  "elementType": "geometry",
                  "stylers": [
                      {
                          "color": "#000000"
                      },
                      {
                          "lightness": 16
                      }
                  ]
              },
              {
                  "featureType": "transit",
                  "elementType": "geometry",
                  "stylers": [
                      {
                          "color": "#000000"
                      },
                      {
                          "lightness": 19
                      }
                  ]
              },
              {
                  "featureType": "water",
                  "elementType": "geometry",
                  "stylers": [
                      {
                          "color": "#000000"
                      },
                      {
                          "lightness": 17
                      }
                  ]
              }
          ]
        };

        // Get the HTML DOM element that will contain your map
        // We are using a div with id="map" seen below in the <body>
        var mapElement = document.getElementById('map');

        // Create the Google Map using our element and options defined above
        var map = new google.maps.Map(mapElement, mapOptions);

        // Let's also add a marker while we're at it
        var marker = new google.maps.Marker({
            position: new google.maps.LatLng(45.613560, -73.666930),
            map: map,
            icon: '/img/site/map-pin.svg',
            title: 'Metonor'
        });
      }
    }

    // Return success
    return true;
  };

  /**
   * Getter for application config.
   * @public
   */
  const getConfig = function getConfig(option) {
    return config[option] ? config[option] : false;
  };

  /**
   * Expose public methods & properties.
   */
  return {
    init,
    config: getConfig,
  };
}());
